var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-community" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-sheet",
                    { attrs: { "max-width": "400px" } },
                    [
                      _c("v-img", {
                        staticClass: "mb-5",
                        attrs: {
                          src: require("@/assets/images/icons/icon-message2-mew.svg"),
                          "max-width": "65px",
                          "max-height": "65px",
                          "aspect-ratio": "1",
                        },
                      }),
                      _c("app-block-title", {
                        attrs: { "no-page-title": "", data: _vm.titleData },
                      }),
                      _c("mew-button", {
                        staticClass: "mt-5",
                        attrs: {
                          "btn-link": "https://help.myetherwallet.com/en/",
                          "has-full-width": false,
                          title: _vm.$t("aboutUs.get-answers.btn-title"),
                          "btn-size": "xlarge",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-sheet",
                    { attrs: { "max-width": "400px" } },
                    [
                      _c("v-img", {
                        staticClass: "mb-5",
                        attrs: {
                          src: require("@/assets/images/icons/icon-community-mew.svg"),
                          "max-width": "65px",
                          "max-height": "65px",
                          "aspect-ratio": "1",
                        },
                      }),
                      _c("app-block-title", {
                        attrs: {
                          "no-page-title": "",
                          data: _vm.titleDataRight,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mt-5" },
                        _vm._l(_vm.icons, function (i, key) {
                          return _c(
                            "v-btn",
                            {
                              key: key,
                              staticClass: "mr-4",
                              attrs: {
                                text: "",
                                icon: "",
                                color: "textDark",
                                href: i.link,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: i.img, height: "27px" },
                              }),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }